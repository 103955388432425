import {
  Section
} from "./styled";
import { withTheme } from "styled-components";
import ResponsiveImage from "../../../components/Responsive/Image";
import { layout } from "../../../constants/styleguide";

const index = () => {
  return (
    <Section>
      <h3 className="title">Who We Work With</h3>
      <ResponsiveImage
        mobile={{
          src: "/images/closets-home/who-we-work-with-mobile.png",
          width: 4526,
          height: 1421,
          alt: "Who We Work With",
          layout: "responsive"
        }}
        desktop={{
          src: "/images/closets-home/who-we-work-with.png",
          width: 6349,
          height: 962,
          alt: "Who We Work With",
          layout: "responsive"
        }}
      />
    </Section>
  )
}

export default withTheme(index);