import React, { useState } from "react";
import Loading from "../../../../../../components/Loading";
import {
  Container,
} from "../styled";
import FormConsultation from "../../../../../Forms/Consultation";

const SmallForm = ({ formId }) => {

  return (
    <>
      <Container>
        <FormConsultation formId={formId} />
      </Container>
    </>
  );
};

export default SmallForm;
