import Iframe from "react-iframe";
import { Container, IframeContainer, VirtualDescription } from "./styled";
import { Row, Col } from "antd";
import Typography from "../../../components/Typography";
import PButton from "../../../components/PButton";
import Link from "next/link";
import useMobile from "../../../hooks/useMobile";

const VirtualSection = ({ iframe, description, buttonProps }) => {
  const isMobile = useMobile();
  return (
    <Container>
      <Row align="middle" justify="center" >

        <Col order={isMobile ? 2 : 1} xs={24} md={12}>
          <VirtualDescription>

            <Row align="middle" gutter={[16, 8]}>
              <Col xs={0} md={24} >
                <Typography
                  color={"#762738"}
                  fontWeight="bold"
                  fontSize="2.25rem"
                  textAlign="left"
                  fontFamily="Lora"
                  style={{ letterSpacing: "0.1rem",padding: ".5rem" }}
                  id="virtual-tour"
                >
                  Take a Virtual Tour
                </Typography>
              </Col>
              <Col xs={24}>
                <Typography
                  color={"black"}
                  fontWeight="normal"
                  fontSize="1.2rem"
                  textAlign="left"
                  fontFamily="Roboto medium"
                >
                  You can explore every corner of the closet, from the racks to the shelves, and get a complete understanding of its layout and functionality. Whether you&apos;re looking for a walk-in closet or a reach-in closet, our 360-degree view provides a fully immersive and interactive experience, allowing you to visualize the closet as if you were standing in it. Don&apos;t just imagine your dream closet, experience it with our 360-degree view!
                </Typography>

              </Col>
              <Col xs={24}>
                <Link href="/360-page">
                  <a>
                    <PButton
                      ptype="default"
                      pname="VIRTUAL TOURS"
                      pbottom="15px"
                      ptop="15px"
                      pleft="15px"
                      pright="15px"
                    />
                  </a>
                </Link>
              </Col>
            </Row>
          </VirtualDescription>
        </Col>
        <Col order={isMobile ? 1 : 2} xs={24} md={12}>
          <Row>
            <Col xs={24} md={0} >
              <Typography
                color={"#762738"}
                fontWeight="bold"
                fontSize="2.25rem"
                textAlign="center"
                fontFamily="Lora"
                style={{ letterSpacing: "0.1rem", padding: ".5rem" }}
                id="virtual-tour"
              >
                Take a Virtual Tour
              </Typography>
            </Col>
          </Row>
          <IframeContainer>
          <Iframe 
              url={"https://momento360.com/e/u/bdc1030798264f9dbaa2bc8e45aa83c0"}
              width="100%"
              height="100%"
              id="virtual-tour-iframe"
              className="virtual-tour-iframe"
              title="virtual tour"
              // display="initial"
              // position="absolute"
              allowFullScreen
              frameBorder={0}
            />
          </IframeContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default VirtualSection;