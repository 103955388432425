import {
  Container,
} from "../styled";
import FormConsultation from "../../../../../Forms/Consultation";

const LargeForm = ({ formId }) => {

  return (
    <>
      <Container>
        <FormConsultation formId={formId} variant="large" />
      </Container>
    </>
  );
};

export default LargeForm;
