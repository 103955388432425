import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin: 0 5.8rem 3rem 5.8rem;

  @media only screen and (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    margin: 0 2rem 3rem 2rem;
  }

  @media only screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    margin: 0 2rem 3rem 2rem;
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    margin: 0 1rem 3rem 1rem;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
`;

const Item = styled.div`
  padding: 0 10px 20px 10px;
  text-align: center; /* Center text */
`;

const Image = styled.img`
  max-width: 100%; /* Adjust to ensure images fit well on mobile */
  height: auto;
`;

const Heading = styled.h2`
  margin: 10px 0 0 0;
  padding: 10px 0 0 0;
  color: #000;
  font-size: 1rem;
  font-weight: medium;
  font-family: 'Roboto Medium';
  line-height: 1.2;
  text-align: center;
`;

const MissionStatement = () => {
  return (
    <Container>
      <Column>
        <Item>
          <Image src="/images/closets-home/mission/mission-1.png" alt="Industry leading Production Times" />
          <Heading>Industry Leading Production Times</Heading>
        </Item>
      </Column>
      <Column>
        <Item>
          <Image src="/images/closets-home/mission/mission-2.png" alt="Over 250 High Quality Material Options" />
          <Heading>Over 250 High Quality Material Options</Heading>
        </Item>
      </Column>
      <Column>
        <Item>
          <Image src="/images/closets-home/mission/mission-3.png" alt="Custom Designed to Fit Your Space" />
          <Heading>Custom Designed to Fit Your Space</Heading>
        </Item>
      </Column>
      <Column>
        <Item>
          <Image src="/images/closets-home/mission/mission-4.png" alt="Financing Available at Most Locations" />
          <Heading>Financing Available at Most Locations</Heading>
        </Item>
      </Column>
    </Container>
  );
};

export default MissionStatement;
