import Styled from 'styled-components';
const PADDING_TOP_CONTENT = 10; // same as DesignHomeOffice component. Must match

export const Container = Styled.div`
  width: 100%;
  padding: 1em;
  position: relative;
  z-index: 1;
  padding-top: ${PADDING_TOP_CONTENT / 2}rem;

  &:after {

    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 25%; /* Set the height to 50% of the container's height */
    background-color: #F1F0EF;
  }

  button {
    border-radius: 0px;
    border: 2px solid #762738;
    margin: auto;
    span {
      color: #762738;
    }
  }
`;

export const IframeContainer = Styled.div`
  width: 100%;
  position: relative;
  min-height: 400px;
  aspect-ratio: 7/4;
`;

export const VirtualDescription = Styled.div`
  width: 80%;
  padding: 2rem 0;
  padding-top: ${PADDING_TOP_CONTENT / 2}rem;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 2rem 1rem;
  }

`;