import styled from "styled-components";

export const Section = styled.section`

 .title {
  font-family: "Lora Bold", serif;
  margin-top:2em;
  font-size: 2.25rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.classyRed};
 }
`;